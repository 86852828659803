<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                v-if="$can('post:create')"
                :to="{ name: 'add-customer-flow' }"
                variant="primary"
              >
                <span class="text-nowrap">Add customer flow</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refOrderTable"
          class="position-relative"
          :items="fetchOrders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: arrivalDate -->
          <template #cell(branchId)="data">
            <span class="text-capitalize">
              {{ mapBranch(data.item.branchId) }}
            </span>
          </template>
          <!-- Column: createdAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: arrivalDate -->
          <template #cell(cukcukOrderId)="data">
            <span class="text-capitalize">
              #{{ data.item.cukcukOrderId }}
            </span>
          </template>
          <!-- Column: arrivalDate -->
          <template #cell(totalAmount)="data">
            <span class="text-capitalize">
              {{ data.item.totalAmount }}
            </span>
            <b-badge
              v-if="!data.item.totalAmount"
              pill
              variant="light-warning"
              class="text-capitalize"
            >
              None
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('roomService:read')"
              :to="{ name: 'detail-order', params: { id: data.item.id } }"
            >
              View
            </b-link>
            |
            <b-link
              v-if="$can('roomService:delete')"
              @click="cancelOrder(data.item.id)"
            >
              Cancel
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrder"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import useOrder from './orderManager'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    BBadge,
  },
  computed: {
    ...mapGetters('cukcuk', ['paymentStatus']),
  },
  methods: {
    mapPayment(data) {
      return this.paymentStatus.find(x => x.value === data).label
    },
    mapBranch(data) {
      const foundBranch = this.branchOptions.find(x => x.value === data)
      if (foundBranch) {
        return foundBranch.label
      }
      return data

      // return data ? this.branchOptions.find(x => x.value === data).label : 'None'
      // return data
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    cancelOrder(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel this order.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('cukcuk/cancelOrder', { id })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
  },

  setup() {
    const {
      timeout,
      showTable,
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrder,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refOrderTable,
      refetchData,
      branchOptions,
    } = useOrder()
    return {
      timeout,
      showTable,
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrder,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refOrderTable,
      refetchData,
      branchOptions,
      // Filter
      avatarText,

      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
