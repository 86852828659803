import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrder() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refOrderTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: false },
    { key: 'customerName', sortable: false },
    { key: 'branchId', label: 'branch', sortable: false },
    // { key: 'orderSource', sortable: false },
    // { key: 'orderType', sortable: false },
    { key: 'cukcukOrderId', sortable: false },
    { key: 'totalAmount', sortable: false },
    { key: 'createdAt', sortable: false },
    { key: 'action' },
  ]
  const perPage = ref(10)
  const totalOrder = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const branchOptions = ref([])
  const dataMeta = computed(() => {
    const localItemsCount = refOrderTable.value
      ? refOrderTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrder.value,
    }
  })

  const refetchData = () => {
    refOrderTable.value.refresh()
  }
  const fetchOrders = (ctx, callback) => {
    store
      .dispatch('order/fetchOrders', {
        order: 'DESC',
        orderBy: 'updatedAt',
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const oders = response.body.data
        totalOrder.value = response.body.meta.itemCount
        callback(oders)
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchBranch = () => {
    store.dispatch('cukcuk/fetchCukCukBranch').then(res => {
      if (res.body.Data.length > 0) {
        branchOptions.value = res.body.Data.map(x => ({
          label: x.Name,
          value: x.Id,
        }))
      }
    })
  }
  fetchBranch()
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrder,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refOrderTable,
    refetchData,
    branchOptions,
    // Extra
  }
}
